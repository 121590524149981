const SwiperStage = () =>{

    // Função construtura do Swiper
    function construct_swiper(allStages, classSwiper){
        if(allStages.length > 0){
            const ContainerSwiper = document.querySelector(`.${classSwiper} .swiper-wrapper`);

            allStages.forEach((stage) => {
               // console.log(stage);
                ContainerSwiper.innerHTML += `<div class="swiper-slide">${stage.outerHTML}</div>`; 
            });
        }
    }

    //Função inicializadora do Swiper
    function swiper_init(classSwiper){
        const swiper = new Swiper(`.${classSwiper}`, {
            slidesPerView: 1.225,
            spaceBetween: 30,
            autoplay: {
                delay: 4000,
            },
            breakpoints:{
                500:{
                    slidesPerView: 1.225,
                },
                640:{
                    slidesPerView: 1.6,
                },
                720:{
                    slidesPerView: 1.8,
                },
                1200:{
                    slidesPerView: 2.5,
                }
            }
        })
    }

    function main(){
        /**  Variáveis Constantes */
        const classSwiper = 'swiper-stages';
        const classBoxItensStages = 'box-stages';
        const classItemStage = 'card-stage';

        const Stages = document.querySelectorAll(`.${classBoxItensStages} .${classItemStage}`);
        if(Stages){
            construct_swiper(Stages, classSwiper);
            swiper_init(classSwiper);
        }

    }

    main();
}

export default SwiperStage;