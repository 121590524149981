export default function contatoForm(){
    const contatoFormSubmit = document.querySelector('#enter-in-contact')
    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
        event.preventDefault();
        disabledBtn()
        const form_data = {
            nome: document.querySelector('#enter-in-contact input[name=form_Nome]').value,
            email: document.querySelector('#enter-in-contact input[name=form_E-mail]').value,
            mensagem: document.querySelector('#enter-in-contact textarea[name=form_Mensagem]').value
        }
        console.log(form_data);
        const url = "/fale-conosco/"
        axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
    });

    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        GLOBAL.showToastify(message)
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector("#enter-in-contact .bt-enter-in-contact[type=submit]")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.classList.add("disabled")
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.classList.remove("disabled")
    }
}