const Nav = () =>{

    function main(){
        const idNavPrincipal = 'nav-principal';

        const NavPrincipal = document.querySelector(`#${idNavPrincipal}`);

        document.addEventListener("scroll", (e)=>{
            const ScrollTop = e.target.scrollingElement.scrollTop;
            if(ScrollTop > 80){
                NavPrincipal.classList.add("active");
            }
            else{
                NavPrincipal.classList.remove("active");
            }
        })
    }
    main();
}

export default Nav;