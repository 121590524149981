// PAGES
const pageHome = document.querySelector("#page__home");
const pageCompliance = document.querySelector("#page__compliance");
const pageTrabalheConosco = document.querySelector("#page__trabalhe_conosco");

// SCRIPT SENHA LOGIN
import mostrarSenha from "./mostrarSenha.js"
import showFormErrors from "./showFormErrors.js";

mostrarSenha()
showFormErrors()

// SECTIONS
/*import salebrandPortfolio from "./salebrand/salebrandPortfolio.js";
import salebrandSobre from "./salebrand/salebrandSobre.js";
import salebrandClientes from "./salebrand/salebrandClientes.js";
import salebrandContato from "./salebrand/salebrandContato.js";

salebrandPortfolio();
salebrandSobre();
salebrandClientes();
salebrandContato();*/

/** SWIPERS */
import SwiperStage from "./swiper/swiperStages.js";

import Button_Hamburge from "./system/button-hamburge.js";
import Nav from "./system/nav.js";
import FormContact from "./system/form.js";


// ★ Pages Scopo
if (pageHome) {
  document.body.classList.add("page__home");

  Button_Hamburge();
  SwiperStage();
  Nav();
  FormContact();
}
else if(pageCompliance){
  document.body.classList.add("page__compliance");

  Button_Hamburge();
  Nav();
  FormContact();
}
else if(pageTrabalheConosco){
  document.body.classList.add("page__trabalhe_conosco");
  
  Button_Hamburge();
  Nav();
}

document.addEventListener("DOMContentLoaded", () =>
  document.body.classList.add("dcl")
);
